import React, { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { ContactHistoryDetails } from '../../../../types';
import ContactHistoryItem from './ContactHistoryItem';
import TimesCalledWarning from '../TimesCalledWarning';
import DownChevron from '../../icons/down-chevron-green.svg';
import './contact-history-overview.scss';

export enum HeaderType {
    Phone,
    Initiation,
    Queue,
    Intent,
    Date,
    Duration,
    Sentiment,
    CallStatus,
    CampaignName,
    EndTime
}

const ContactHistoryOverview: React.FC = () => {
    const { contactHistory, setContactHistory, timesCalled } = useContext(AppContext);
    const [selectedHeader, setSelectedHeader] = useState(HeaderType.Date);

    const handleHeaderClick = (header: HeaderType) => {
        const originalContractHistory = [...contactHistory];

        if (selectedHeader === header) {
            const contactHistoryReversed = originalContractHistory.reverse();
            setContactHistory(contactHistoryReversed);
            return;
        }

        switch (header) {
            case HeaderType.Phone: {
                const sortedHistoryByPhone = originalContractHistory.sort((a, b) =>
                    a.phoneNumber.toLowerCase().localeCompare(b.phoneNumber.toLowerCase())
                );
                setContactHistory(sortedHistoryByPhone);
                break;
            }
            case HeaderType.Initiation: {
                const sortedHistoryByInitiation = originalContractHistory.sort((a, b) =>
                    a.initiationMethod.toLowerCase().localeCompare(b.initiationMethod.toLowerCase())
                );
                setContactHistory(sortedHistoryByInitiation);
                break;
            }

            case HeaderType.CallStatus: {
                const sortedByCallStatus = originalContractHistory.sort((a, b) =>
                    a.callStatus!.toLowerCase().localeCompare(b.callStatus!.toLowerCase())
                );

                setContactHistory(sortedByCallStatus);

                break;
            }

            case HeaderType.CampaignName: {
                const sortedByCampaignName = originalContractHistory.sort((a, b) =>
                    a.campaignName!.toLowerCase().localeCompare(b.campaignName!.toLowerCase())
                );

                setContactHistory(sortedByCampaignName);

                break;
            }
            case HeaderType.Queue: {
                const sortedHistoryByQueue = originalContractHistory.sort((a, b) =>
                    a.queue.toLowerCase().localeCompare(b.queue.toLowerCase())
                );
                setContactHistory(sortedHistoryByQueue);
                break;
            }
            case HeaderType.Intent: {
                const sortedHistoryByIntent = originalContractHistory.sort((a, b) =>
                    a.intent.toLowerCase().localeCompare(b.intent.toLowerCase())
                );
                setContactHistory(sortedHistoryByIntent);
                break;
            }
            case HeaderType.Date: {
                const sortedHistoryByDate = originalContractHistory.sort((a, b) =>
                    a.callDate.toLowerCase().localeCompare(b.callDate.toLowerCase())
                );
                setContactHistory(sortedHistoryByDate);
                break;
            }
            case HeaderType.Duration: {
                const sortedHistoryByDuration = originalContractHistory.sort(
                    (a, b) => a.callDurationMs - b.callDurationMs
                );
                setContactHistory(sortedHistoryByDuration);
                break;
            }
            case HeaderType.Sentiment: {
                const sortedHistoryBySentiment = originalContractHistory.sort((a, b) =>
                    a.sentimentAnalysis
                        .toLowerCase()
                        .localeCompare(b.sentimentAnalysis.toLowerCase())
                );
                setContactHistory(sortedHistoryBySentiment);
                break;
            }
            case HeaderType.EndTime: {
                const sortedHistoryByEndTime = originalContractHistory.sort((a, b) =>
                    a.endTime.toLowerCase().localeCompare(b.endTime.toLowerCase())
                );
                setContactHistory(sortedHistoryByEndTime);
                break;
            }
        }

        setSelectedHeader(header);
    };

    return (
        <div className="contact-history-overview" data-testid="contact-history-overview">
            {timesCalled > 1 && (
                <div className="contact-history-overview-calls">
                    <TimesCalledWarning />
                </div>
            )}

            <div className="flex contact-history-overview__headers">
                <HeaderItem
                    className="f2"
                    label="PHONE NUMBER"
                    onClick={() => handleHeaderClick(HeaderType.Phone)}
                />
                <HeaderItem
                    className="f2"
                    label="INITIATION"
                    onClick={() => handleHeaderClick(HeaderType.Initiation)}
                />
                <HeaderItem
                    className="f2"
                    label="CALL STATUS"
                    onClick={() => handleHeaderClick(HeaderType.CallStatus)}
                />

                <HeaderItem
                    className="f2"
                    label="CAMPAIGN NAME"
                    onClick={() => handleHeaderClick(HeaderType.CampaignName)}
                />
                <HeaderItem
                    className="f2"
                    label="QUEUE"
                    onClick={() => handleHeaderClick(HeaderType.Queue)}
                />
                <HeaderItem
                    className="f1"
                    label="INTENT"
                    onClick={() => handleHeaderClick(HeaderType.Intent)}
                />
                <HeaderItem
                    className="f2"
                    label="DATE"
                    onClick={() => handleHeaderClick(HeaderType.Date)}
                />
                <HeaderItem
                    className="f1"
                    label="DURATION"
                    onClick={() => handleHeaderClick(HeaderType.Duration)}
                />
                <HeaderItem
                    className="f1"
                    label="CALL COMPLETED TIME"
                    onClick={() => handleHeaderClick(HeaderType.EndTime)}
                />
                <HeaderItem
                    className="f1"
                    label="SENTIMENT"
                    onClick={() => handleHeaderClick(HeaderType.Sentiment)}
                />
            </div>

            <ul className="contact-history-overview__items">
                {(contactHistory as ContactHistoryDetails[]).map(
                    (item: ContactHistoryDetails, key: number) => (
                        <ContactHistoryItem
                            data={item}
                            index={key}
                            key={`${key}-${new Date().getTime()}`}
                        />
                    )
                )}
            </ul>
        </div>
    );
};

const HeaderItem = ({
    label,
    className,
    onClick
}: {
    label: string;
    className: string;
    onClick: () => void;
}) => {
    const [rotate, setRotate] = useState<boolean>(false);

    const updateSort = () => {
        onClick();
        setRotate(!rotate);
    };

    return (
        <h2 className={className} onClick={updateSort}>
            {label}
            <img src={DownChevron} alt={label} className={rotate ? 'flipped' : ''} />
        </h2>
    );
};

export default ContactHistoryOverview;
