import React from 'react';
import { TimeElapsed } from '@missionlabs/smartagent-app-components';

interface Props {
    time: number;
}

export const Footer: React.FC<Props> = ({ time }) => (
    <footer>
        <div className="flex between middle">
            <span>00:00</span>
            <span>
                {' '}
                <TimeElapsed noTick date={TimeElapsed.from(time)} />{' '}
            </span>
        </div>
    </footer>
);
