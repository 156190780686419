import React from 'react';
import { Button } from '@missionlabs/smartagent-app-components';

import MuteCh1Off from './icons/MuteCh1Off.svg';
import MuteCh1On from './icons/MuteCh1On.svg';
import { Waveform } from './Waveform';

interface Props {
    currentTimePercentage: number;
    id: number;
    muted: boolean;
    onSeek(e: React.MouseEvent<HTMLDivElement>, seek: boolean): void;
    muteChannel(ch: number): void;
}

export const Track: React.FC<Props> = ({
    currentTimePercentage,
    id,
    muted,
    onSeek,
    ...actions
}) => {
    const getMutedIcon = () => {
        return muted ? MuteCh1On : MuteCh1Off;
    };

    const getAltString = () => {
        if (id === 1) return muted ? 'Unmute agent' : 'Mute agent';

        return muted ? 'Unmute customer' : 'Mute customer';
    };

    return (
        <div className={`track track-${id}`}>
            <Button onClick={() => actions.muteChannel(id)} className="mute" title={getAltString()}>
                <img src={getMutedIcon()} alt={getAltString()} title={getAltString()} />
            </Button>

            <Waveform currentTimePercentage={currentTimePercentage} onSeek={onSeek} />
        </div>
    );
};
