/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../AppContext';
import { BASE_URL } from '../utils';

export const useRequestContactHistory = (
    phoneNumber: string,
    token: string,
    hasReceivedEvent: boolean,
    setHasReceivedEvent: (hasReceivedEvent: boolean) => void,
    isTetheredCall: boolean,
    accountNumber?: string
): { loading: boolean } => {
    const [loading, setLoading] = useState(false);
    const { setContactHistory, setTimesCalled } = useContext(AppContext);

    const url = `${BASE_URL}/contact-history/${phoneNumber}`;

    useEffect(() => {
        if (!phoneNumber || phoneNumber === 'anonymous') {
            setContactHistory([]);
            setTimesCalled(0);
            return;
        }
        getData();
    }, [phoneNumber]);


    useEffect(() => {
        if(accountNumber && hasReceivedEvent) {
            getData();
            setHasReceivedEvent(false);
        }
    }, [accountNumber, hasReceivedEvent])

    const getData = async () => {
        setLoading(true);
        try {
            const { data } = await axios({
                url,
                headers: {
                    'X-Amz-Security-Token': token
                },
                params: { isTetheredCall, acqueonCustomerNumber: accountNumber }
            });

            setContactHistory(data.contactHistoryDetails);
            setTimesCalled(data.totalCallsInLast14Days);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return {
        loading
    };
};
