import React, { createContext, Dispatch, SetStateAction, useState } from 'react';
import { ContactHistoryDetails } from '../../types';

interface AppContextType {
    contactHistory: Array<ContactHistoryDetails> | [];
    setContactHistory: Dispatch<SetStateAction<ContactHistoryDetails[]>>;
    selectedContactHistoryIndex: number;
    setSelectedContactHistoryIndex: Dispatch<SetStateAction<number>>;
    showDetailsView: boolean;
    setShowDetailsView: Dispatch<SetStateAction<boolean>>;
    timesCalled: number;
    setTimesCalled: Dispatch<SetStateAction<number>>;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const AppContext = createContext<AppContextType>({
    contactHistory: [],
    setContactHistory: () => {},
    selectedContactHistoryIndex: 0,
    setSelectedContactHistoryIndex: () => {},
    showDetailsView: false,
    setShowDetailsView: () => {},
    timesCalled: 0,
    setTimesCalled: () => {}
});
/* eslint-enable @typescript-eslint/no-empty-function*/

export const AppProvider = ({ children }: any) => {
    const [contactHistory, setContactHistory] = useState([] as ContactHistoryDetails[]);
    const [selectedContactHistoryIndex, setSelectedContactHistoryIndex] = useState(0);
    const [showDetailsView, setShowDetailsView] = useState(false);
    const [timesCalled, setTimesCalled] = useState(0);

    return (
        <AppContext.Provider
            value={{
                contactHistory,
                setContactHistory,
                selectedContactHistoryIndex,
                setSelectedContactHistoryIndex,
                showDetailsView,
                setShowDetailsView,
                timesCalled,
                setTimesCalled
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
