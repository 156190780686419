import React, { useContext } from 'react';
import './transcript.scss';
import Message from './Message';
import { TranscriptionContext } from '../ContactHistoryDetails/TranscriptionContext';
import { TranscriptMessage } from '../../../../types';

interface Props {
    messages: TranscriptMessage[];
}

const Transcript: React.FC<Props> = ({ messages }) => {
    const { seekProgress } = useContext(TranscriptionContext);

    const isMessageFocused = (
        seekProgress: number | undefined,
        messageStartTime: string | undefined,
        nextMessageStartTime: string | undefined
    ) => {
        if (!seekProgress || !messageStartTime) return false; // context doesnt exist or info missing so bail

        const parsedMessageStartTime = parseInt(messageStartTime);

        if (seekProgress < parsedMessageStartTime) return false; // we are behind this message

        if (!nextMessageStartTime) return true; // we are on the last message

        const parsedNext = parseInt(nextMessageStartTime);

        if (seekProgress < parsedNext) return true; // we are in front of current but behind next

        return false; // next must also be higher
    };

    return (
        <div className="flex column sa-transcript">
            {messages.map((message, i, arr) => (
                <Message
                    key={i}
                    focused={isMessageFocused(
                        seekProgress,
                        message.startTime,
                        arr[i + 1]?.startTime
                    )}
                    message={message}
                />
            ))}
        </div>
    );
};

export default Transcript;
