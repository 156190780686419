import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import warning from '../icons/warning.png';

const TimesCalledWarning: React.FC = () => {
    const { timesCalled } = useContext(AppContext);

    return (
        <>
            {timesCalled > 1 && (
                <div className="warning-banner flex middle">
                    <div className="warning-icon-wrapper">
                        <img src={warning} alt="warning-icon" />
                    </div>
                    <p className="call-text">
                        <span className="bold">Called: </span>
                        {timesCalled} Times in 14 days
                    </p>
                </div>
            )}
        </>
    );
};

export default TimesCalledWarning;
