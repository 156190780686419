import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import ContactHistoryDetailsHeader from './ContactHistoryDetailsHeader';
import TimesCalledWarning from '../TimesCalledWarning';
import CustomerSentiment from '../CustomerSentiment';
import CallTranscription from '../CallTranscription';
import './contact-history-details.scss';
import CallRecording from '../CallRecording';
import { formatEndTime, formatCallDate, formatCallTime } from '../../utils';

const ContactHistoryDetails: React.FC = () => {
    const {
        contactHistory,
        selectedContactHistoryIndex,
        showDetailsView,
        timesCalled
    } = useContext(AppContext);
    const selectedContactHistoryDetails = contactHistory[selectedContactHistoryIndex];
    const formattedCallDate = formatCallDate(selectedContactHistoryDetails.callDate);
    const formattedCallTime = formatCallTime(selectedContactHistoryDetails.callDate);
    const formattedCallDuration = selectedContactHistoryDetails.callDurationMs
        ? new Date(selectedContactHistoryDetails.callDurationMs).toISOString().substr(11, 8)
        : '-';

    return (
        <>
            {showDetailsView && (
                <>
                    <ContactHistoryDetailsHeader />
                    <div
                        className="contact-history-details flex"
                        data-testid="contact-history-details"
                    >
                        <div className="contact-history-details-left">
                            {timesCalled > 1 && (
                                <div className="contact-history-details-calls padding-side">
                                    <TimesCalledWarning />
                                </div>
                            )}

                            <div className="contact-history-details-wrapper padding-side">
                                <Detail
                                    label={'Customer Number'}
                                    value={selectedContactHistoryDetails.phoneNumber}
                                />
                                <Detail
                                    label={'Initiation Method'}
                                    value={selectedContactHistoryDetails.initiationMethod}
                                />
                                <Detail
                                    label={'Reason for Contact'}
                                    value={selectedContactHistoryDetails.intent}
                                />
                                <Detail
                                    label={'Call Status'}
                                    value={selectedContactHistoryDetails.outcome || '-'}
                                />

                                <Detail
                                    label={'Campaign Name'}
                                    value={selectedContactHistoryDetails.campaignName || '-'}
                                />
                                <Detail
                                    label={'Queue'}
                                    value={selectedContactHistoryDetails.queue}
                                />
                                <Detail label={'Type'} value={selectedContactHistoryDetails.type} />
                                <div className="flex column contact-history-details__detail">
                                    <div className="contact-history-details__detail__label">
                                        Customer Sentiment
                                    </div>
                                    <div className="contact-history-details__detail__info">
                                        <CustomerSentiment
                                            sentiment={
                                                selectedContactHistoryDetails.sentimentAnalysis
                                            }
                                        />
                                    </div>
                                </div>
                                <Detail
                                    label={'Time of Call'}
                                    value={`${formattedCallDate}, ${formattedCallTime}`}
                                />
                                <Detail label={'Call Duration'} value={formattedCallDuration} />
                                <Detail label='Call completed time' value={formatEndTime(selectedContactHistoryDetails.endTime)} />
                            </div>
                            <div className="contact-history-details-audio padding-side">
                                <CallRecording
                                    callRecordingUrl={
                                        selectedContactHistoryDetails.callRecordingUrl
                                    }
                                    contactId={selectedContactHistoryDetails.contactId}
                                    callDate={selectedContactHistoryDetails.callDate}
                                />
                            </div>
                        </div>
                        <div className="contact-history-details-right">
                            <CallTranscription
                                messages={selectedContactHistoryDetails.chatTranscription}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

const Detail = ({ label, value }: { label: string; value: string }) => {
    return (
        <div className="flex column contact-history-details__detail">
            <div className="contact-history-details__detail__label">{label}</div>
            <div className="contact-history-details__detail__info">{value}</div>
        </div>
    );
};

export default ContactHistoryDetails;
