import React from 'react';

interface Props {
    currentTimePercentage: number;
    onSeek(e: React.MouseEvent<HTMLDivElement>, seek: boolean): void;
}

export const Waveform: React.FC<Props> = ({ currentTimePercentage, onSeek }) => {
    return (
        <div className="track-player-container">
            <div
                className="track-player no-waveform"
                onClick={(e) => onSeek(e, true)}
                role="presentation"
            >
                <div className="line-container">
                    <div className="line" style={{ width: `${currentTimePercentage}%` }} />
                </div>
            </div>
        </div>
    );
};
