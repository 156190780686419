import React, { Dispatch, PureComponent, SetStateAction } from 'react';
import AudioController from './AudioController';
import axios, { AxiosError } from 'axios';

export interface IStateFns {
    setCh1Muted(muted: boolean): void;
    setCh2Muted(muted: boolean): void;
    setPlaying(playing: boolean): void;
    setSeek(seek: number): void;
    setElapsed(elapsed: number): void;
}

interface Props {
    url: string;
    time?: number;
    updateSeekProgress: Dispatch<SetStateAction<number>>;
    stateFns: IStateFns;
    onError: (err: AudioErrorWithStatusCode) => void;
}

export interface AudioErrorWithStatusCode extends Event {
    statusCode?: number;
}

export class Audio extends PureComponent<Props> {
    public baseAudioEl = React.createRef<HTMLAudioElement>();
    private audioController: AudioController = new AudioController(
        this.props.stateFns,
        this.props.updateSeekProgress
    );

    componentDidMount() {
        this.audioController.init(this.baseAudioEl.current!);
        this.baseAudioEl.current!.onerror = this.audioError;
    }

    private audioError = async (eventOrErrorString: string | Event): Promise<void> => {
        this.audioController.pause();
        const error = (typeof eventOrErrorString === 'string'
            ? new Event(eventOrErrorString)
            : eventOrErrorString) as AudioErrorWithStatusCode;
        //No way to detect what type of error.
        //We manually GET and attach any error status code before we bubble up
        try {
            await axios.get(this.props.url);
        } catch (err) {
            const axiosError = err as AxiosError;
            error.statusCode = axiosError.response?.status;
        } finally {
            this.props.onError?.(error);
        }
    };

    public muteChannel = (ch: number) => {
        if (ch === 1) this.audioController.disconnectLeft();
        if (ch === 2) this.audioController.disconnectRight();
    };

    public unmuteChannel = (ch: number) => {
        if (ch === 1) this.audioController.connectLeft();
        if (ch === 2) this.audioController.connectRight();
    };

    public seek = (to: number) => {
        const { time } = this.props;
        const duration = time ?? this.getDuration();
        if (!this.baseAudioEl.current) return;

        this.audioController.seek(to, duration);
    };

    public playPause = async () => {
        const audio = this.baseAudioEl.current;
        if (!audio) return;

        const time =
            this.props.time && !isNaN(this.props.time) ? this.props.time : this.getDuration();
        return audio.paused ? await this.audioController.play(time) : this.audioController.pause();
    };

    public getDuration = () => this.baseAudioEl.current?.duration ?? 0;
    public getElapsed = () => this.baseAudioEl.current!.currentTime;
    public setPlaybackRate = (selectedSpeed: number) =>
        this.audioController.setAudioPlaybackRate(selectedSpeed);

    public render() {
        const { url } = this.props;
        // eslint-disable-next-line jsx-a11y/media-has-caption
        return <audio crossOrigin="true" src={url} ref={this.baseAudioEl} />;
    }
}
