import React, { createContext, Dispatch, SetStateAction, useState } from 'react';

interface ITranscriptionContext {
    seekTo: number;
    seekProgress: number;
    setSeekTo: Dispatch<SetStateAction<number>>;
    setSeekProgress: Dispatch<SetStateAction<number>>;
}

/* eslint-disable @typescript-eslint/no-empty-function */
export const TranscriptionContext = createContext<ITranscriptionContext>({
    seekTo: 0,
    seekProgress: 0,
    setSeekTo: () => {},
    setSeekProgress: () => {}
});
/* eslint-enable @typescript-eslint/no-empty-function*/

export const TranscriptionProvider = ({ children }: any) => {
    const [seekTo, setSeekTo] = useState(0);
    const [seekProgress, setSeekProgress] = useState(0);

    return (
        <TranscriptionContext.Provider
            value={{
                seekTo,
                seekProgress,
                setSeekTo,
                setSeekProgress
            }}
        >
            {children}
        </TranscriptionContext.Provider>
    );
};
