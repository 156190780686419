import React from 'react';
import Transcript from './Transcript';
import { TranscriptionProvider } from '../ContactHistoryDetails/TranscriptionContext';
import { TranscriptMessage } from '../../../../types';
import searchIcon from '../../icons/search.svg';

interface Props {
    messages: TranscriptMessage[];
}

const CallTranscription: React.FC<Props> = ({ messages }) => (
    <>
        {messages && messages.length > 0 ? (
            <TranscriptionProvider>
                <Transcript messages={messages} />
            </TranscriptionProvider>
        ) : (
            <div className="sa-transcript-no-data">
                <img src={searchIcon} alt="Search" width="24px" height="24px" />
                <p>We were unable to transcribe this conversation</p>
            </div>
        )}
    </>
);

export default CallTranscription;
