import React from 'react';
import PlayIcon from './icons/Play-noFill.svg';
import PauseIcon from './icons/Pause-noFill.svg';
import { Button, TimeElapsed } from '@missionlabs/smartagent-app-components';
interface Props {
    elapsed?: number;
    total: number;
    isPlaying: boolean;
    playPause(): void;
}

export const Controls: React.FC<Props> = ({ elapsed, total, isPlaying, ...actions }) => {
    return (
        <div className="sa-audioplayer-controls-wrapper">
            <div className="sa-audioplayer-controls">
                <TimeElapsed
                    ariaLive="off"
                    hours
                    format="hh:mm:ss"
                    noTick
                    date={TimeElapsed.from(elapsed ?? 0)}
                />
                <span>{' / '}</span>
                <TimeElapsed
                    ariaLive="off"
                    hours
                    format="hh:mm:ss"
                    noTick
                    date={TimeElapsed.from(total)}
                />

                <Button styling="blank" onClick={actions.playPause}>
                    <img
                        src={isPlaying ? PauseIcon : PlayIcon}
                        alt={isPlaying ? 'Pause' : 'Play'}
                        title={isPlaying ? 'Pause' : 'Play'}
                    />
                </Button>
            </div>
        </div>
    );
};
