import React, { useContext } from 'react';
import classNames from 'classnames';
import { Button } from '@missionlabs/smartagent-app-components';
import { TranscriptionContext } from '../ContactHistoryDetails/TranscriptionContext';
import { TranscriptMessage } from '../../../../types';
import CustomerSentiment from '../CustomerSentiment';

interface Props {
    message: TranscriptMessage;
    focused: boolean;
}

const Message: React.FC<Props> = ({ message, focused }) => {
    const { setSeekTo } = useContext(TranscriptionContext);

    const seekTo = () => {
        if (!message.startTime) return;
        setSeekTo(message.startTime);
    };

    return (
        <div
            className={classNames({
                message: true,
                outgoing: message.channel === 0,
                incoming: message.channel === 1
            })}
        >
            <Button
                onClick={seekTo}
                className={classNames({
                    focused
                })}
            >
                <span aria-label={message.channel ? 'agent says' : 'customer says'}></span>
                <span>{message.content}</span>
                <time className="message-timestamp">{message.startTime ?? '-'}</time>{' '}
            </Button>

            {!!message.sentiment && (
                <CustomerSentiment sentiment={message.sentiment} className="message-sentiment" />
            )}
        </div>
    );
};

export default Message;
