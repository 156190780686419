import './audio-player.scss';
import React, { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { Dropdown } from '@missionlabs/smartagent-app-components';
import { Track } from './Track';
import { Seeker } from './Seeker';
import { Audio } from './Audio';
import { Controls } from './Controls';
import { Footer } from './Footer';
import useSize from './useSize';
import DownloadIcon from './icons/Download.svg';
import { TranscriptionContext } from '../ContactHistoryDetails/TranscriptionContext';

interface Props {
    canDownload: boolean;
    onAudioError: (err: any) => void;
    contactID?: string;
    recordingDate?: string;
    signedUrl: string;
}

const playbackRateOptions = [
    { label: 'Speed: 0.5x', data: '0.5' },
    { label: 'Speed: 1x', data: '1.0' },
    { label: 'Speed: 2x', data: '2.0' },
    { label: 'Speed: 3x', data: '3.0' }
];
// default 1x
const defaultSelectedPlaybackOption = playbackRateOptions[1];

export const AudioPlayer: React.FC<Props> = ({
    signedUrl,
    canDownload,
    onAudioError,
    contactID,
    recordingDate
}) => {
    const [playing, setPlaying] = useState(false);
    const [ch1Muted, setCh1Muted] = useState(false);
    const [ch2Muted, setCh2Muted] = useState(false);
    const [elapsed, setElapsed] = useState(0);
    const [downloadingAudioFile, setDownloadingAudioStatus] = useState(false);
    const [downloadProgressAmount, setDownloadProgressAmount] = useState(0);
    const [currentPlaybackOption, setCurrentPlaybackOption] = useState(
        defaultSelectedPlaybackOption
    );

    const { seekTo, setSeekProgress } = useContext(TranscriptionContext);
    // Don't use setSeek directly, use audio.current.seek()
    const [seek, setSeek] = useState(0);

    const { size: trackSize, ref } = useSize<HTMLDivElement>(true);

    const audio = useRef<Audio>(null);

    const time = audio.current?.getDuration() ?? 0;
    const onSeek = (e: React.MouseEvent<HTMLDivElement>, seek = true) => {
        const muteButtonWidth = 50;
        const paddingOnAudio = 12;
        const selectedXCoord =
            e.clientX - (trackSize.offsetLeft + muteButtonWidth + paddingOnAudio);
        const widthOfAudioBar = trackSize.width - muteButtonWidth - paddingOnAudio * 2;
        let newProgressPercentage = (selectedXCoord / widthOfAudioBar) * 100;
        if (newProgressPercentage < 0) {
            return;
        }

        if (newProgressPercentage > 100) {
            newProgressPercentage = 100;
        }

        if (seek) audio.current?.seek(newProgressPercentage);
        return newProgressPercentage;
    };

    const muteChannel = (ch: number) => {
        if (ch === 1)
            return ch1Muted ? audio.current?.unmuteChannel(1) : audio.current?.muteChannel(1);

        if (ch === 2)
            return ch2Muted ? audio.current?.unmuteChannel(2) : audio.current?.muteChannel(2);
    };

    const resetDownloadProgress = () => {
        setDownloadingAudioStatus(false);
        setDownloadProgressAmount(0);
    };

    const handleAudioDownload = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        if (downloadingAudioFile) {
            return;
        }

        try {
            setDownloadingAudioStatus(true);
            let progressAmount = 0;
            // download the audio file as a blob from the signedUrl
            const audioFileResponse = await axios.get(signedUrl, {
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                    const percent = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
                    // only update state every time progress has increased by 1% to stop unnecessary
                    if (percent >= progressAmount + 1) {
                        progressAmount = percent;
                        setDownloadProgressAmount(percent);
                    }
                }
            });

            // create a more readable filename for the recording with the contact id and date
            const fileName = `contact_${contactID}__time_${recordingDate}`;

            // get the browser to download the file for the user with the new filename
            const url = window.URL.createObjectURL(new Blob([audioFileResponse.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}.wav`);
            link.click();

            resetDownloadProgress();
        } catch (err) {
            console.log(err);
            window.open(signedUrl, '_blank');
            resetDownloadProgress();
        }
    };

    useEffect(() => {
        const seekToSeconds = (s: number) => audio.current?.seek((s / time!) * 100);
        seekToSeconds(seekTo);
    }, [seekTo, time]);

    //reset settings when navigating to a different recording
    useEffect(() => {
        audio.current?.seek(0);

        return () => {
            setElapsed(0);
            setPlaying(false);
            setCurrentPlaybackOption(defaultSelectedPlaybackOption);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signedUrl]); // urls are unique which allows us to force re-render when recording changes

    const playbackRateOnChange = (option: any) => {
        setCurrentPlaybackOption(option);
        audio.current?.setPlaybackRate(parseFloat(option.data));
    };

    return (
        <div className="audio-player">
            <div className="audio-player__header flex middle between">
                <h3>Audio</h3>
                <div className="flex middle end">
                    <Controls
                        elapsed={elapsed}
                        isPlaying={playing}
                        total={!isNaN(time) ? time : 0}
                        playPause={async () => await audio.current?.playPause()}
                    />
                    <Dropdown
                        options={playbackRateOptions}
                        onChange={playbackRateOnChange}
                        value={currentPlaybackOption?.label || ''}
                    />

                    {canDownload ? (
                        <a
                            className={`sa-audio-download lg-pad-right flex middle ${
                                downloadingAudioFile ? 'is-downloading' : ''
                            }`}
                            href={signedUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            onClick={(e) => handleAudioDownload(e)}
                        >
                            {downloadingAudioFile ? (
                                <>
                                    <span className="sa-audio-download-spinner">
                                        <ClipLoader color="#fff" size={15} />
                                    </span>
                                    <span className="sa-audio-downloading-text">
                                        {downloadProgressAmount}%
                                    </span>
                                </>
                            ) : (
                                <>
                                    <img src={DownloadIcon} alt="Download" title="Download" />
                                    Download
                                </>
                            )}
                        </a>
                    ) : null}
                </div>
            </div>
            <div ref={ref} className="sa-audioplayer">
                <Seeker width={trackSize.width} to={seek} onSeek={onSeek} />

                <Track
                    currentTimePercentage={seek}
                    id={1}
                    muted={ch1Muted}
                    muteChannel={muteChannel}
                    onSeek={onSeek}
                />

                <Track
                    currentTimePercentage={seek}
                    id={2}
                    muted={ch2Muted}
                    muteChannel={muteChannel}
                    onSeek={onSeek}
                />
                <Footer time={!isNaN(time) ? time : 0} />
            </div>

            <Audio
                time={time}
                url={signedUrl}
                ref={audio}
                updateSeekProgress={setSeekProgress}
                stateFns={{
                    setSeek,
                    setCh1Muted,
                    setCh2Muted,
                    setPlaying,
                    setElapsed
                }}
                onError={onAudioError}
            />
        </div>
    );
};
