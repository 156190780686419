import React, { useState } from 'react';

interface Props {
    to: number;
    width: number;
    onSeek(e: React.MouseEvent<HTMLElement>, seek?: boolean): number | undefined;
}

export const Seeker: React.FC<Props> = ({ to, width, ...actions }) => {
    const [tempLeft, setTempLeft] = useState<number | undefined>(undefined);

    const getSeekPosition = (to: number) => ({ left: to + '%' });

    const onDrag = (e: React.DragEvent<HTMLDivElement>) => {
        const to = actions.onSeek(e, false);
        setTempLeft(to);
    };

    const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
        actions.onSeek(e);
        setTempLeft(undefined);
    };

    return (
        <div onDrag={onDrag} onDragEnd={onDragEnd} className="seeker-wrapper">
            <div style={getSeekPosition(tempLeft ?? to)} className="seeker" />
        </div>
    );
};
