const env = process.env.REACT_APP_ENV || 'dev';
export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/${env}`;

export const formatEndTime = (timestamp: string): string => {
    const formattedEndTime = timestamp.substr(11, 5);
    const timeFrame = parseInt(formattedEndTime.slice(0, 2)) > 11 ? 'PM' : 'AM';

    return `${formattedEndTime} ${timeFrame}`;
};

export const formatCallDate = (timestamp: string): string => {
    return timestamp.substr(0, 10).split('-').reverse().join('/');
};

export const formatCallTime = (timestamp: string): string => {
    return timestamp.substr(11, 5);
};
