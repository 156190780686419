import { Dispatch, SetStateAction } from 'react';
import { IStateFns } from './Audio';

export default class AudioController {
    private gainLeft?: GainNode;
    private gainRight?: GainNode;
    private stereoMerger?: ChannelMergerNode;
    private audioContext?: AudioContext;

    private baseAudioEl?: HTMLAudioElement;

    private interval?: NodeJS.Timeout;
    private contextUpdateProgress: Dispatch<SetStateAction<number>>;
    private stateFns: IStateFns;
    private initalised: boolean;

    constructor(stateFns: IStateFns, contextUpdateProgress: Dispatch<SetStateAction<number>>) {
        this.stateFns = stateFns;
        this.contextUpdateProgress = contextUpdateProgress;
        this.initalised = false;
    }

    init = (baseAudioEl: HTMLAudioElement) => {
        this.baseAudioEl = baseAudioEl;

        this.baseAudioEl.onended = () => {
            this.stateFns.setPlaying(false);
        };
    };

    connectLeft = () => {
        if (!this.audioContext) this.makeContext();

        this.gainLeft!.connect(this.stereoMerger!, 0, 0);
        this.stateFns.setCh1Muted(false);
    };
    disconnectLeft = () => {
        if (!this.audioContext) this.makeContext();

        this.gainLeft!.disconnect(this.stereoMerger!);
        this.stateFns.setCh1Muted(true);
    };
    connectRight = () => {
        if (!this.audioContext) this.makeContext();

        this.gainRight!.connect(this.stereoMerger!, 0, 1);
        this.stateFns.setCh2Muted(false);
    };
    disconnectRight = () => {
        if (!this.audioContext) this.makeContext();

        this.gainRight!.disconnect();
        this.stateFns.setCh2Muted(true);
    };

    public play = async (time: number) => {
        if (!this.baseAudioEl) return;

        if (!this.audioContext) this.makeContext();

        if (this.interval) clearInterval(this.interval);

        const { stateFns, contextUpdateProgress } = this;
        this.interval = setInterval(() => {
            contextUpdateProgress(this.baseAudioEl!.currentTime);

            //time is NaN....
            stateFns.setSeek((this.baseAudioEl!.currentTime / time) * 100);
            stateFns.setElapsed(this.baseAudioEl!.currentTime);
        }, 100);

        await this.baseAudioEl.play();
        stateFns.setPlaying(true);
    };

    public seek = (to: number, time: number) => {
        if (isNaN(to) || isNaN(time)) return;
        if (!this.baseAudioEl) return;

        const audio = this.baseAudioEl;
        if (!audio) return;
        audio.currentTime = (to / 100) * time;
        this.stateFns.setSeek(to);
    };

    public pause = () => {
        if (!this.baseAudioEl) return;

        if (this.interval) clearInterval(this.interval);
        this.baseAudioEl.pause();
        this.stateFns.setPlaying(false);
    };

    public makeContext = () => {
        const ctx = new AudioContext();

        const gainLeft = ctx.createGain();
        const gainRight = ctx.createGain();

        gainLeft.gain.value = 1;
        gainRight.gain.value = 1;

        const merger = ctx.createChannelMerger(2);
        const splitter = ctx.createChannelSplitter(2);

        const src = ctx.createMediaElementSource(this.baseAudioEl!);

        src.connect(splitter, 0, 0);

        splitter.connect(gainLeft, 0);
        splitter.connect(gainRight, 1);

        gainLeft.connect(merger, 0, 0);
        gainRight.connect(merger, 0, 1);

        merger.connect(ctx.destination, 0, 0);

        [this.gainLeft, this.gainRight, this.stereoMerger, this.audioContext] = [
            gainLeft,
            gainRight,
            merger,
            ctx
        ];
    };

    public setAudioPlaybackRate = (selectedSpeed: number) => {
        if (!this.baseAudioEl) return;
        this.baseAudioEl.playbackRate = selectedSpeed;
    };
}
