import React, { useContext } from 'react';
import { ContactHistoryDetails } from '../../../../types';
import { AppContext } from '../../AppContext';
import CustomerSentiment from '../CustomerSentiment';
import { formatEndTime, formatCallDate, formatCallTime } from '../../utils';
interface Props {
    data: ContactHistoryDetails;
    index: number;
}

const ContactHistoryItem: React.FC<Props> = ({ data, index }) => {
    const { setShowDetailsView, setSelectedContactHistoryIndex } = useContext(AppContext);
    const formattedCallDate = formatCallDate(data.callDate);
    const formattedCallTime = formatCallTime(data.callDate);
    const formattedCallDuration = data.callDurationMs
        ? new Date(data.callDurationMs).toISOString().substr(14, 5)
        : '-';

    const handleHistoryItemClick = () => {
        setShowDetailsView(true);
        setSelectedContactHistoryIndex(index);
    };

    const renderCallStatus = () => {
        let isSuccess: boolean | undefined;
        const { callStatus } = data;
        if (callStatus === undefined) {
            isSuccess = undefined
        } else if (callStatus === 0 || callStatus === 4 || callStatus >= 100) {
            isSuccess = true
        } else {
            isSuccess = false;
        }

        return (
            <div className="call-status-wrapper">
                {isSuccess !== undefined ? (
                    <>
                        <div className={`status-circle ${isSuccess ? 'green' : 'red'}`} />
                        <p>{isSuccess ? 'Successful' : 'Failed'}</p>
                    </>
                ) : '-'}
            </div>
        );
    }

    return (
        <li className="contact-history-item flex middle" onClick={() => handleHistoryItemClick()}>
            <p className="f2 contact-history-item__value">{data.phoneNumber}</p>
            <p className="f2 contact-history-item__value">{data.initiationMethod}</p>
            <div className="f2 contact-history-item__value contact-history-item-call-status">
                {renderCallStatus()}
            </div>
            <p className="f2 contact-history-item__value">{data.campaignName}</p>
            <p className="f2 contact-history-item__value">{data.queue}</p>
            <p className="f1 contact-history-item__value">{data.intent}</p>
            <p className="f2 contact-history-item__value">{`${formattedCallDate}, ${formattedCallTime}`}</p>
            <p className="f1 contact-history-item__value">{formattedCallDuration}</p>
            <p className="f1 contact-history-item__value">{formatEndTime(data.endTime)}</p>
            <div className="f1 contact-history-item__value contact-history-item-sentiment">
                <CustomerSentiment sentiment={data.sentimentAnalysis} />
            </div>
        </li>
    );
};

export default ContactHistoryItem;
