import React from 'react';
import { AudioPlayer } from './AudioPlayer';
import { TranscriptionProvider } from '../ContactHistoryDetails/TranscriptionContext';
import Search from '../../icons/search.svg';

interface Props {
    callRecordingUrl?: string;
    contactId?: string;
    callDate?: string;
}

const CallRecording: React.FC<Props> = ({ callRecordingUrl, contactId, callDate }) => {
    return !callRecordingUrl ? (
        <div className="audio-player-error flex middle center">
            <img src={Search} alt="Search" width="24px" height="24px" />
            <p>Unable to retrieve call recording data</p>
        </div>
    ) : (
        <TranscriptionProvider>
            <AudioPlayer
                signedUrl={callRecordingUrl}
                contactID={contactId}
                recordingDate={callDate}
                canDownload={false}
                onAudioError={() => {
                    console.log(`Audio Error for: ${callRecordingUrl}`);
                }}
            />
        </TranscriptionProvider>
    );
};

export default CallRecording;
