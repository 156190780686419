import { useRef, useState, useLayoutEffect } from 'react';

// This hook is awesome but may cause an infinite loop. Do not use the size given by this hook
// to make a decision on whether to change size of something else as this hook will fire again and you'll end up with a
// call stack overflow.
export default function useSize<T extends HTMLElement>(noZero = false) {
    const [size, setSize] = useState({ height: 0, width: 0, offsetLeft: 0 });
    const ref = useRef<T>(null);
    useLayoutEffect(() => {
        if (!ref.current) return;

        if (noZero && (ref.current.clientHeight === 0 || ref.current.clientWidth === 0)) return;

        setSize({
            height: ref.current.clientHeight,
            width: ref.current.clientWidth,
            offsetLeft: ref.current.offsetLeft
        });

        // eslint-disable-next-line
    }, [ref, ref.current?.clientWidth]);

    return { size, ref };
}
