import React, { useContext } from 'react';
import { Button } from '@missionlabs/smartagent-app-components';
import LeftChevron from '../../icons/left-chevron.svg';
import RightChevron from '../../icons/right-chevron.svg';
import LeftChevronGreen from '../../icons/left-chevron-green.svg';
import { AppContext } from '../../AppContext';

const ContactHistoryDetailsHeader: React.FC = () => {
    const {
        contactHistory,
        selectedContactHistoryIndex,
        setSelectedContactHistoryIndex,
        setShowDetailsView
    } = useContext(AppContext);

    const onPrevItem = () => {
        const totalItems = contactHistory.length;
        selectedContactHistoryIndex === 0
            ? setSelectedContactHistoryIndex(totalItems - 1)
            : setSelectedContactHistoryIndex(selectedContactHistoryIndex - 1);
    };

    const onNextItem = () => {
        const totalItems = contactHistory.length;
        selectedContactHistoryIndex === totalItems - 1
            ? setSelectedContactHistoryIndex(0)
            : setSelectedContactHistoryIndex(selectedContactHistoryIndex + 1);
    };

    return (
        <div className="details-header">
            <div className="details-header-left">
                <Button onClick={() => setShowDetailsView(false)} className="details-header__back">
                    <img src={LeftChevronGreen} alt="Back" />
                    Back
                </Button>
                <p className="details-header__contact">
                    <span>Contact ID: </span>
                    {contactHistory[selectedContactHistoryIndex].contactId}
                </p>
            </div>
            <div className="details-header-navigator">
                <Button onClick={onPrevItem} className="chevron-button left">
                    <img src={LeftChevron} alt="Left arrow" />
                </Button>
                <p className="page-number">
                    {selectedContactHistoryIndex + 1} of {contactHistory.length}
                </p>
                <Button onClick={onNextItem} className="chevron-button right">
                    <img src={RightChevron} alt="Right arrow" />
                </Button>
            </div>
        </div>
    );
};

export default ContactHistoryDetailsHeader;
