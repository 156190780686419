import React from 'react';
import iconNegative from '../icons/sentiment-negative.svg';
import iconNeutral from '../icons/sentiment-neutral.svg';
import iconPositive from '../icons/sentiment-positive.svg';

enum TranscriptSentiment {
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
    NEUTRAL = 'NEUTRAL'
}

const icons = {
    NEGATIVE: iconNegative,
    NEUTRAL: iconNeutral,
    POSITIVE: iconPositive
};

interface Props {
    sentiment: string;
    className?: string;
}

const CustomerSentiment: React.FC<Props> = ({ sentiment, className }: Props) => {
    return (
        <>
            {!sentiment ? null : (
                <img
                    className={className}
                    src={icons[TranscriptSentiment[sentiment as keyof typeof TranscriptSentiment]]}
                    alt={`${
                        TranscriptSentiment[sentiment as keyof typeof TranscriptSentiment]
                    }-sentiment`}
                />
            )}
        </>
    );
};

export default CustomerSentiment;
