import React, { useEffect, useRef, useContext, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import SmartAgent from '@missionlabs/smartagent-app-components/dist/smartagent';
import { Box } from '@missionlabs/smartagent-app-components';
import '@missionlabs/smartagent-app-components/dist/index.css';
import ContactHistoryOverview from './ContactHistoryOverview/ContactHistoryOverview';
import ContactHistoryDetails from './ContactHistoryDetails/ContactHistoryDetails';
import { AppContext } from '../AppContext';
import { useRequestContactHistory } from '../hooks/useRequestContactHistory';
import { ContactHistoryContact, AccountNumberMessageReceived } from '../../../types';

const Main: React.FC = (props: any) => {
    const sm = useRef<SmartAgent>(props.sm || new SmartAgent());
    const { contactHistory, showDetailsView } = useContext(AppContext);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [accountNumber, setAccountNumber] = useState<string | undefined>();
    const [token, setToken] = useState('');
    const [hasReceivedEvent, setHasReceivedEvent] = useState<boolean>(false);
    const [isTetheredCall, setIsTetheredCall] = useState<boolean>(false);
    const { loading } = useRequestContactHistory(
        phoneNumber,
        token,
        hasReceivedEvent,
        setHasReceivedEvent,
        isTetheredCall,
        accountNumber
    );

    useEffect(() => {
        setup();
        // eslint-disable-next-line
    }, []);

    const setup = async () => {
        let contact;
        if (props.contact) {
            contact = props.contact;
            sm.current.init();
        } else {
            contact = await sm.current.init();
        }
        console.log('[tw-contact-history plugin] got contact', contact);
        setToken(await sm.current.getToken());
        setIsTetheredCall(contact?.attributes ? !!contact.attributes['sa-tag-tethered'] : false);
        setPhoneNumber(contact.customerEndpointAddress);

        sm.current.on('contact_updated', async (contact: ContactHistoryContact) => {
            if (contact.customerEndpointAddress) {
                setToken(await sm.current.getToken());
                setPhoneNumber(contact.customerEndpointAddress);
            }
        });

        sm.current.on('acqueon_contact_updated', async (message: AccountNumberMessageReceived) => {
            if (message.source === 'tw-acqueon-plugin') {
                setHasReceivedEvent(true);
                setAccountNumber(message.accountNumber);
                console.log('received account number: ', message.accountNumber);
            }
        });

        sm.current.showPlugin();
    };

    const renderInner = () => {
        return loading ? (
            <div className="plugin-loading" data-testid="plugin-loading">
                <BeatLoader />
            </div>
        ) : contactHistory && contactHistory.length > 0 ? (
            <div className="plugin">
                {showDetailsView ? <ContactHistoryDetails /> : <ContactHistoryOverview />}
            </div>
        ) : (
            <div className="plugin-error">No data available</div>
        );
    };

    const isEmbedded = window.parent !== window;
    return isEmbedded ? (
        renderInner()
    ) : (
        <Box alt header="Contact History" collapse>
            {renderInner()}
        </Box>
    );
};

export default Main;
